import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { useSearchParams } from "react-router-dom";

// Parasail imports
import {  FetchBillingAPI } from "service/FetchService";
import { Button } from '@mui/material';

interface Props {}

const BillingPaymentInfo: React.FC<Props> = () => {
    const [isSubscriptionSuccess, setIsSubscriptionSuccess] = React.useState(false);
    const [isSubscriptionCancelled, setIsSubscriptionCancelled] = React.useState(false);
    const [isSubscribed, setIsSubscribed] = React.useState(false);

  // get any params from url
  const [searchParams] = useSearchParams();
  
  const setActiveAccount = React.useCallback(() => {
    setIsSubscribed(currentIsSubscribed => !currentIsSubscribed);
  }, []);

    React.useEffect(() => {
        // check if there is a subscription
        FetchBillingAPI.getSubscribed().then((response: String) => {
            if(response) {
                setActiveAccount();
            }
        }).catch((e: any) => {
            console.log(e);
        });
    }, [setActiveAccount]);

    React.useEffect(() => {
        if(searchParams.get("isSubscriptionSuccess") && searchParams.get("isSubscriptionSuccess") === 'true') {
            setIsSubscriptionSuccess(true);
        }

        if(searchParams.get("isSubscriptionCancelled") && searchParams.get("isSubscriptionCancelled") === 'true') {
            setIsSubscriptionCancelled(true);
        }
        
    }, [searchParams, setIsSubscriptionSuccess, setIsSubscriptionCancelled]);

    const handleCreateSubscription = (event: React.MouseEvent<HTMLElement>) => {
        //event.currentTarget
        FetchBillingAPI.createSubscription().then((response: String) => {
            console.log('createSubscription response', response);
            if (response) {
               window.location.href = response.toString();
            }
        }).catch((e: any) => {
            console.log(e);
        });
    };

    const handleCancelSubscription = (event: React.MouseEvent<HTMLElement>) => {
        // FetchBillingAPI.cancelSubscription().then((response: String) => {
        //     console.log('cancelSubscription response', response);
        //     // if (response) {
        //     //    window.location.href = response.toString();
        //     // }
        // }).catch((e: any) => {
        //     console.log(e);
        // });
    };

    console.log('isSubscriptionSuccess/isSubscribed', isSubscriptionSuccess, isSubscribed);
    return (<>
        <Grid size={{ xs: 6, md: 6 }} sx={{ mt: 1, maxWidth: "300px" }}>
            {/* <div className="items-center text-med text-gray-800  ">
                <Typography variant="h6" component="div">
                    Payment details
                </Typography>
            </div> */}
            <div className="mt-3 text-med text-gray-800  ">
            { false ? 
                <>            
                    <Typography variant="subtitle1" component="span">
                        Your subscription is active.
                    </Typography>
                    <Button variant="contained" onClick={handleCancelSubscription} className="ml-3" style={{textTransform :"none"}}>
                        Cancel subscription
                    </Button>
                </>
            :
                <>{ isSubscriptionSuccess ?
                    (<Typography variant="subtitle1" component="div">
                        { isSubscriptionCancelled ? 
                            "Your subscription is cancelled."
                        :
                            "Thanks for subscribing."
                        }
                    </Typography>)
                :
                    <>
                        <Typography variant="subtitle1" component="span">
                            You have no active subscription.
                        </Typography>
                        <Button variant="contained" onClick={handleCreateSubscription} className="mt-3" style={{textTransform :"none"}}>Create subscription</Button>
                    </>
                }</>
            }
            </div>
        </Grid>
    </>);
}

export default BillingPaymentInfo;