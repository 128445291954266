import * as React from 'react';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import moment from 'moment';
import HighlightOff from '@mui/icons-material/HighlightOff';
import Tooltip from '@mui/material/Tooltip';

// Parasail imports
import CustomNoRowsOverlay from "components/tables/CustomNoRowsOverlay";
import { BillingDedicated, BillingDedicatedItemType } from "react-app-env";
import { Logger } from "utils/Logger";

interface IMyProps { 
    data: BillingDedicated
}
const columnsDeployments: GridColDef[] = [
  { field: 'name', width: 300, renderHeader: () => (
    <strong>
      {' Design Name'}
    </strong>
  ), renderCell: (params: GridRenderCellParams) => {
    const isDeleted = params.row.deleted;
    const value = params.value ? <>
      {params.value}
      {isDeleted &&
        <Tooltip sx={{marginLeft: 1}} title="Has been destroyed" placement="right" arrow>
          <HighlightOff fontSize="small" color="info" /> 
        </Tooltip>
      }
    </>: '';
    return (
    <>      
      {value}
    </>
    );
  }},
  { field: 'duration', width: 200, renderHeader: () => (
    <strong>
      {'Duration'}
    </strong>
  ), renderCell: (params: GridRenderCellParams) => {
    const totalDuration = params.value;
    const duration = Math.floor(moment.duration(totalDuration).asDays()).toString() + ' days ' + moment.utc(totalDuration).format("HH [hrs] mm [mins]");
    return (
    <>      
      {duration}
    </>
    );
  } },
  { field: 'costRate', width: 100, renderHeader: () => (
    <strong>
      {'Cost Rate'}
    </strong>
  ), renderCell: (params: GridRenderCellParams) => {
      const costRate = params.value ? params.value < .01 ? `< $0.01` : `$${params.value.toFixed(2)}` : '0';
      return (
      <>      
        {costRate}
      </>
      );
    } },
  { field: 'cost', width: 150, renderHeader: () => (
    <strong>
      {'Cost'}
    </strong>
  ), renderCell: (params: GridRenderCellParams) => {
      const cost = params.value ? params.value < .01 ? `< $0.01` : `$${params.value.toFixed(2)}` : '0';
      return (
      <>      
        {cost}
      </>
      );
    } },
    { field: 'gpuType', width: 150, renderHeader: () => (
      <strong>
        {'Gpu Type'}
      </strong>
    ) }
];

const BillingOverviewBreakdownDedicated = ({data}: IMyProps): React.JSX.Element => {
  const [totalDeploymentsDuration, setTotalDeploymentsDuration] = React.useState<string>();
  const [totalDeploymentsCost, setTotalDeploymentsCost] = React.useState<string>();
  const [billingDataDeploymentsItems, setBillingDataDeploymentsItems] = React.useState(Array<BillingDedicatedItemType>);

  React.useEffect(() => {
    Logger.log('data2', data);
    if(data && data.deployments) {
      setBillingDataDeploymentsItems(data.deployments.items as Array<BillingDedicatedItemType>);
      const totalDuration = data?.deployments?.duration;
      const duration = Math.floor(moment.duration(totalDuration).asDays()).toString() + ' days ' + moment.utc(totalDuration).format("HH [hrs] mm [mins]");
      setTotalDeploymentsDuration(duration);
      const total = data?.deployments?.cost && data?.deployments?.cost < .01 ? `< $0.01`: `$${data?.deployments?.cost.toFixed(2)}`;
      setTotalDeploymentsCost(total);
    }
  }, [data, data?.deployments]);

  return (<>
    <div className=" " style={{maxWidth: '900px' }}>
      <Stack direction="row" spacing={1} sx={{ mt: 3 }}>
        <Card variant="outlined" style={{width: '100%', borderBottom: "none"}}>
          <Box sx={{ p: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" component="div">
                Total Duration
              </Typography>
              <Typography variant="subtitle1" component="div">
                <span className="font-bold flex items-center text-med text-blue-400">{totalDeploymentsDuration}</span>
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" component="div">
                Total Cost
              </Typography>
              <Typography variant="subtitle1" component="div">
                <span className="font-bold flex items-center text-med text-blue-400">{totalDeploymentsCost}</span>
              </Typography>
            </Stack>
          </Box>
        </Card>
      </Stack>

      <DataGrid 
        rows={billingDataDeploymentsItems} 
        columns={columnsDeployments}
        getRowId={(row) => row.id + "-" + row.gpuType}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
        sx={{ '--DataGrid-overlayHeight': '300px', '& .MuiDataGrid-scrollbar--horizontal': {overflowX: 'hidden'} }}
        // checkboxSelection
      />
    </div>
  </>);
}

export default BillingOverviewBreakdownDedicated;