import * as React from 'react';
import { DataGrid, GridColDef, GridActionsCellItem, GridRenderCellParams} from '@mui/x-data-grid';
import { useLoaderData, useNavigation, useParams, useLocation } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid2';
import { CopyBlock } from 'react-code-blocks';

// Parasail imports
import { ApiKey } from "react-app-env";
import BasicBreadcrumbs from "components/BasicBreadcrumbs";
import PageHeader from "components/PageHeader";
import CustomNoRowsOverlay from "components/tables/CustomNoRowsOverlay";
import { DedicatedDeployment } from "react-app-env";
import {  FetchDedicatedAPI } from "service/FetchService";
import { Logger } from "utils/Logger";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const KeysView = () => {
  const [dedicatedDeploymentID, setDedicatedDeploymentID] = React.useState('');
  const [dedicatedDeployment, setDedicatedDeployment] = React.useState<DedicatedDeployment>(Object);
  const [open, setOpen] = React.useState(false);
  const [currentKey, setCurrentKey] = React.useState<ApiKey>(Object);
  const [currentKeys, setCurrentKeys] = React.useState(Array<ApiKey>);
  const [openCreateKey, setOpenCreateKey] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dedicatedKey, setDedicatedKey] = React.useState<ApiKey>(Object);

  const keys = useLoaderData() as any[];
  const navigation = useNavigation();

  const { id } = useParams();
  // get the pass thru state
  let location = useLocation();

  React.useEffect(() => {
    if (id) {
      setDedicatedDeploymentID(id?.toString());
    }

    if (keys) {
      setCurrentKeys(keys);
    }
  }, [id, keys]);

  React.useEffect(() => {
    // set the pass through state data (e.g., DedicatedDeployment object)
    const model = location?.state;
    if (model) {
      setDedicatedDeployment(model);
    }
  }, [location]);

  if (navigation.state === "loading") {
    return <h1>Loading keys ...</h1>;
  }

  const handleOpen = (key: ApiKey) => {
    setCurrentKey(key);
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  const columns: GridColDef[] = [
    { field: 'name', width: 350,renderHeader: () => (
      <strong>
        {'Key Name'}
      </strong>
    ) },
    { field: 'createdAt', width: 250, renderHeader: () => (
      <strong>
        {'Created Date'}
      </strong>
    ) },
    { field: 'spend', width: 250, renderHeader: () => (
      <strong>
        {'Spend'}
      </strong>
    ), renderCell: (params: GridRenderCellParams) => {
      const cost = params.value ? params.value < .01 ? `< $0.01` : `$${params.value}` : '';
       return (
       <>      
         {cost}
       </>
       );
     } },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        Logger.log('getActions', params)
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleOpen(params.row)}
            color="inherit"
            // component={Link}
            // to={`/counterparties/${params.id}`}
          />,
        ];
      },
      renderHeader: () => (
        <strong>
          {'Action'}
        </strong>
      )
    }
  ];

  const handleDeleteKeyClick = () => {
    Logger.log('delete key', currentKey);
    // delete key
    FetchDedicatedAPI.setDeploymentKeyDestroy(dedicatedDeploymentID, currentKey?.id).then((response) => {
      Logger.log('setDeploymentKeyDestroy response', dedicatedDeploymentID, currentKey?.id, response);

      // update key list
      setCurrentKeys(currentKeys?.filter(key => key?.id?.toString() !== currentKey?.id?.toString()));
      handleClose();
    }).catch((e) => {
      Logger.log(e);
    });
  };

  const handleCloseCreateKey = () => setOpenCreateKey(false);
  const handleOpenCreateKey = () => {
    Logger.log('handleCreateKey');

    if(dedicatedDeploymentID) {
      setLoading(true);
      setOpenCreateKey(true);

      // Generate key
      FetchDedicatedAPI.setDeploymentKey(dedicatedDeploymentID).then((response) => {
        Logger.log('handleCreateKey response', response, dedicatedDeploymentID);
        setLoading(false);
        if(response) {
          // Save new key to show in modal
          setDedicatedKey(response);
          // Update key list
          setCurrentKeys([...currentKeys, response]);
        }
      }).catch((e) => {
        Logger.log('Generate key fetch error: ', e.code);
      });
    }
  }

  return (
    <>
      <BasicBreadcrumbs root="Dedicated Deployments" primary="Api Keys" url={`/dedicated/${dedicatedDeploymentID}`} />

      <PageHeader title={`Api Keys for ${dedicatedDeployment.deploymentName}`} />

      <div className=" " style={{ height: '500px', width: '100%' }}>
        <Stack direction="row" style={{maxWidth: '150px', padding: "15px 0"}}>
          <LoadingButton
            variant="contained"
            onClick={handleOpenCreateKey}
            loading={loading}
            loadingIndicator="Opening modal..."
            size="small"
          >
            Create key
          </LoadingButton>
        </Stack>
        <DataGrid 
          rows={currentKeys} 
          columns={columns} 
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          slots={{ noRowsOverlay: CustomNoRowsOverlay }}
          sx={{ '--DataGrid-overlayHeight': '300px' }}
          // checkboxSelection
        />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Confirm delete of key {currentKey?.name}?
          </Typography>
          <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1} sx={{ mt: 2 }} style={{padding: "15px", border: "1px solid #DDD", backgroundColor: "#f7f6f9"}}>
            <LoadingButton 
              variant="outlined" 
              onClick={handleClose} 
              loadingIndicator="Canceling..."
              size="small"
            >
              Cancel
            </LoadingButton>  
            <LoadingButton 
              variant="outlined" 
              onClick={handleDeleteKeyClick} 
              // disabled={showDeployButton}
              loadingIndicator="Deleting..."
              // loading={loading}
              size="small"
            >
              Confirm
            </LoadingButton>  
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openCreateKey}
        onClose={handleCloseCreateKey}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            New Api Key
          </Typography>
          <div className="mt-2 mb-2">Please save this key for future use. You will not see this key again.</div>
          <Grid style={{ paddingLeft: "0", alignContent: "center" }}>
            {dedicatedKey && Object.keys(dedicatedKey).length !== 0 ? (
              <Grid container>
                <Grid sx={{minWidth: '100%', backgroundColor: "#f7f6f9"}}>  
                  <CopyBlock
                    text={dedicatedKey?.token || ""}
                    language={"plaintext"}
                    showLineNumbers={false}
                    wrapLongLines={true}
                  />
                </Grid>
              </Grid>
            ) : <div className="mt-2 mb-2"></div>}
          </Grid>
          <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1} sx={{ mt: 2 }} style={{padding: "15px", border: "1px solid #DDD", backgroundColor: "#f7f6f9"}}>
            <LoadingButton 
              variant="outlined" 
              onClick={handleCloseCreateKey} 
              loading={loading}
              loadingIndicator="Canceling..."
              size="small"
            >
              Close
            </LoadingButton>  
            {/* <LoadingButton 
              variant="contained" 
              onClick={handleCreateKey} 
              // disabled={showDeployButton}
              loading={loading}
              loadingIndicator="Generating key..."
              size="small"
              endIcon={<Key />}
            >
              Generate Key
            </LoadingButton> */}
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
export default KeysView;