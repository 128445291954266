import * as React from 'react';
import BasicBreadcrumbs from "components/BasicBreadcrumbs";
import PageHeader from "components/PageHeader";
import Box from '@mui/material/Box';
import { Panel, PanelGroup } from "react-resizable-panels";
import { useSearchParams, useLocation } from "react-router-dom";

// Parasail imports
import ResizeHandle from "components/views/common/ResizeHandle";
import ModelEditPane from "components/views/common/ModelEditPane";
import ModelCompareChartPane from "components/views/common/ModelCompareChartPane";
import { FetchDedicatedAPI } from "service/FetchService";
import { DedicatedJsonModel, DedicatedDeployment, Cluster, PerformanceEstimate } from "react-app-env";
import { useUserContext } from "components/providers/UserContext";
import { Logger } from "utils/Logger";

const DEDICATED_DEFAULT: DedicatedDeployment  = {
  deploymentName: '',
  modelName: '',
  serviceTierName: '',
  gatewayId: 0,
  costInputToken: '',
  costOutputToken: '',
  draftModelName: '',
  replicas: 1,
  scaleDownPolicy: 'INACTIVE',
  scaleDownThreshold: 28800000,
  disableErrorRecovery: false,
  externalAlias: "ignored",
  shared: false,
  generative: false,
  embedding: false,
  imageTag: '',
};

const DedicatedCreateView = () => {
  const [modelsData, setModelsData] = React.useState(Array<DedicatedJsonModel>);// models from json
  const [dedicatedDeployment, setDedicatedDeployment] = React.useState<DedicatedDeployment>(DEDICATED_DEFAULT);// current dedicated deployment

  const [gatewaysData, setGatewaysData] = React.useState(Array<Cluster>);
  const [performanceData, setPerformanceData] = React.useState<PerformanceEstimate>({ userTps: [], cost1mt: [], tpsContext: []});

  const [tpsList, setTpsList] = React.useState(Array<{ batchSizes: number; values: number; contextSize: string; }>);
  const [costList, setCostList] = React.useState(Array<{ batchSizes: number; values: number; contextSize: string; }>);
  const [tpsContext, setTpsContext] = React.useState(Array<{ batchSizes: number; values: number; contextSize: string; }>);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [isCloneMode, setIsCloneMode] = React.useState(false);
  const [isDashBoard, setIsDashBoard] = React.useState(false);
  const [isServerlessDashboard, setIsServerlessDashboard] = React.useState(false);

  const { UserState, isUserContextLoading } = useUserContext();

  // get any params from url
  const [searchParams] = useSearchParams();
  
  // get the pass thru state
  let location = useLocation();

  React.useEffect(() => {    
    // fetch models from JSON file
    FetchDedicatedAPI.getModelsFromJson().then((response) => {
      setModelsData(response as DedicatedJsonModel[]);
    }).catch((e) => {
      Logger.log(e);
    });

    // fetch dedicated gateways
    FetchDedicatedAPI.getGateways().then((response) => {
      setGatewaysData(response as any);
    }).catch((e) => {
      Logger.log(e);
    });
  }, []);

  React.useEffect(() => {
    // set the pass through state data (DedicatedDeployment object) from deployment dashboard
    const deploymentModel = location?.state; // get pass-thru deployment model
    const isEditMode = searchParams.size !== 0 && searchParams.get("isEditMode");
    const isCloneMode = searchParams.size !== 0 && searchParams.get("isCloneMode");

    if (deploymentModel) {
      // save pass-thru deployment model
      setDedicatedDeployment(deploymentModel);
    } else {
      // on create new. Add defaults if needed.
      setDedicatedDeployment(d => {
        return {
          ...d,
          gatewayId: Number(gatewaysData[0]?.id)
        }
      });
    }

    if(isEditMode === 'true') {
      setIsEditMode(true);
    }
    if(isCloneMode === 'true') {
      setIsCloneMode(true);
    }
  }, [location, searchParams, setDedicatedDeployment, gatewaysData]);

  React.useEffect(() => {    
    //get from url param and save as default Design Name's input value
    const designName = searchParams.get("designName");
    // Update the current dedicatedDeployment's deploymentName with the url's param pass-thru
    if(designName && designName !== '') {
      setDedicatedDeployment(d => {
        return {...d, deploymentName: designName}
      });
    }

    // const isCloneable = isCloneMode && searchParams.size !== 0 && designName && designName !== '' && designName !== 'undefined';
    // const hasDedicatedDeployment = dedicatedDeployment && Object.keys(dedicatedDeployment).length !== 0 && deploymentName !== '' && modelName !== '' && serviceTierName !== '';
    // Logger.log('isCloneable', isCloneable);
    // Logger.log('hasDedicatedDeployment', dedicatedDeployment);

    if(searchParams.get("isDashBoard") && searchParams.get("isDashBoard") === 'true') {
      setIsDashBoard(true);
    }
    if(searchParams.get("isServerlessDashboard") && searchParams.get("isServerlessDashboard") === 'true') {
      setIsServerlessDashboard(true);
    }
  }, [searchParams, setDedicatedDeployment]);

  // Data for performance updated. Normalize data to chart.
  React.useEffect(() => {
    Logger.log('updated performanceData!!', performanceData);
    if(performanceData?.userTps?.length !== 0 && performanceData?.cost1mt?.length !== 0) {
      //reset data first
      setTpsList([]);
      setCostList([]);
      setTpsContext([]);
      
      performanceData.userTps.forEach((tpsItem) => {
        const values = tpsItem.values;
        const batchSizes = tpsItem.batchSizes;
        const contextSize = tpsItem.contextSize;
        const data = batchSizes?.map((batch, index) => {
          return {
            batchSizes: batch, 
            values: values[index], 
            contextSize: contextSize.toString()
          }
        })
        Logger.log('data', data);
        setTpsList((currentTPSList) => {
          const mergedData = [ ...currentTPSList, ...data ];
          return mergedData;
        });
        return data;
      });

      performanceData.cost1mt.forEach((costItem) => {
        const values = costItem.values;
        const batchSizes = costItem.batchSizes;
        const contextSize = costItem.contextSize;
        const data = batchSizes?.map((cost, index) => {
          return {
            batchSizes: cost, 
            values: values[index], 
            contextSize: contextSize.toString()
          }
        })
        Logger.log('data', data);
        setCostList((currentCostList) => {
          const mergedData = [ ...currentCostList, ...data ];
          return mergedData;
        });
        return data;
      });

      performanceData.tpsContext.forEach((tpsItem) => {
        const values = tpsItem.values;
        const batchSizes = tpsItem.batchSizes;
        const dataset = tpsItem.dataset;
        const data = batchSizes?.map((batch, index) => {
          return {
            batchSizes: batch,
            values: values[index],
            contextSize: dataset
          }
        })
        Logger.log('data', data);
        setTpsContext((currentTPSList) => {
          const mergedData = [ ...currentTPSList, ...data ];
          return mergedData;
        });
        return data;
      });
    }
  }, [performanceData]);

  if (isUserContextLoading) {
    return <div></div>;
  }

  Logger.log('Current dedicatedDeployment:', dedicatedDeployment);
  const title = isDashBoard && isEditMode ? "Edit Model" : (isCloneMode ? "Clone Dedicated Model" : "Create Dedicated Model");

  return (
    <>
      <BasicBreadcrumbs 
        root={isDashBoard ? "Dedicated Deployments" : isServerlessDashboard ? "Serverless Models" : "Home"} 
        primary={title} url={isDashBoard ? dedicatedDeployment.id ? `/dedicated/${dedicatedDeployment.id}`: '/dedicated' : isServerlessDashboard ? "/serverless" : "/home"} 
      />

      <PageHeader title={title} />

      <div style={{minHeight: "820px", display: "flex", alignItems: "stretch", justifyContent: "center"}}>
        <Box className="w-full" style={{marginBottom: "25px"}} sx={{ bgcolor: 'background.default'}}>
          <PanelGroup direction="horizontal" id="group">
            {/* create panel */}
            <Panel id="sidebar" minSize={52}  defaultSize={52} collapsible={true} order={1} style={{padding: "5px", border: "1px solid #DDD"}}>
              <ModelEditPane 
                modelsData={modelsData}
                setPerformanceData={setPerformanceData}
                dedicatedDeployment={dedicatedDeployment}
                setDedicatedDeployment={setDedicatedDeployment}
                isEditMode={isEditMode}
                isCloneMode={isCloneMode}
                isAdmin={UserState.isAdmin}
              />
            </Panel>
        
            <ResizeHandle />

            {/* charts panel */}
            <Panel collapsible={true} order={2} className=" " style={{padding: '20px', overflow: 'auto', border: "1px solid #DDD"}} >
              {performanceData?.cost1mt?.length !== 0 ? <ModelCompareChartPane designName={dedicatedDeployment.deploymentName} dataTPS={tpsList} dataCost={costList} tpsContext={tpsContext}/> 
              : <p className="py-1 text-sm text-blue-600">Please configure your model on the left and click <strong>ADD TO CHART</strong>.</p>}
            </Panel>
          </PanelGroup>
        </Box>
      </div>
    </>
  );
};

export default DedicatedCreateView;