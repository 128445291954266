import * as React from 'react';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
// import CheckIcon from '@mui/icons-material/Check';
import Grid from '@mui/material/Grid2';
import { DateRangeType } from "react-tailwindcss-datepicker";
import HighlightOff from '@mui/icons-material/HighlightOff';
import Tooltip from '@mui/material/Tooltip';

// Parasail imports
import CustomNoRowsOverlay from "components/tables/CustomNoRowsOverlay";
import { BillingServerless, BillingServerlessItemType } from "react-app-env";
import BillingOverviewBreakdownChart from "components/views/common/BillingOverviewBreakdownChart";
import { Logger } from "utils/Logger";

interface IMyProps { 
    data: BillingServerless,
    dateRange: DateRangeType
}
const columnsKeys: GridColDef[] = [
  { field: 'name', width: 400, renderHeader: () => (
    <strong>
      {'Key Name'}
    </strong>
  ), renderCell: (params: GridRenderCellParams) => {
    const isDeleted = params.row.deleted;
    const value = params.value ? <>
      {params.value}
      {isDeleted &&
        <Tooltip sx={{marginLeft: 1}} title="Has been destroyed" placement="right" arrow>
          <HighlightOff fontSize="small" color="info" /> 
        </Tooltip>
      }
    </>: '';
    return (
    <>      
      {value}
    </>
    );
  }},
  { field: 'cost', width: 250, renderHeader: () => (
    <strong>
      {'Cost'}
    </strong>
  ), renderCell: (params: GridRenderCellParams) => {
     const cost = params.value && params.value < .01 ? `< $0.01`: `$${params.value.toFixed(2)}`;
      return (
      <>      
        {cost}
      </>
      );
    } }
];
const columnsDeployments: GridColDef[] = [
  { field: 'name', width: 400, renderHeader: () => (
    <strong>
      {' Design Name'}
    </strong>
  ), renderCell: (params: GridRenderCellParams) => {
    const isDeleted = params.row.deleted;
    const value = params.value ? <>
      {params.value}
      {isDeleted &&
        <Tooltip sx={{marginLeft: 1}} title="Has been destroyed" placement="right" arrow>
          <HighlightOff fontSize="small" color="info" /> 
        </Tooltip>
      }
    </>: '';
    return (
    <>      
      {value}
    </>
    );
  }},
  { field: 'cost', width: 250, renderHeader: () => (
    <strong>
      {'Cost'}
    </strong>
  ), renderCell: (params: GridRenderCellParams) => {
      const cost = params.value ? params.value < .01 ? `< $0.01` : `$${params.value.toFixed(2)}` : '';
      return (
      <>      
        {cost}
      </>
      );
    } }
];

const BillingOverviewBreakdownServerless = ({data, dateRange}: IMyProps): React.JSX.Element => {
  const [totalKeys, setTotalKeys] = React.useState('');
  const [billingDataKeysItems, setBillingDataKeysItems] = React.useState(Array<BillingServerlessItemType>);
  const [totalDeployments, setTotalDeployments] = React.useState('');
  const [billingDataDeploymentsItems, setBillingDataDeploymentsItems] = React.useState(Array<BillingServerlessItemType>);
  const [alignment, setAlignment] = React.useState('deployments');

  React.useEffect(() => {
    Logger.log('data1', data);
    if(data && data.keys) {
      setBillingDataKeysItems(data.keys.items as Array<BillingServerlessItemType>);
      const total = data?.keys?.cost && data?.keys?.cost < .01 ? `< $0.01`: `$${data?.keys?.cost.toFixed(2)}`;
      setTotalKeys(total);
    }
    if(data && data.deployments) {
      setBillingDataDeploymentsItems(data.deployments.items as Array<BillingServerlessItemType>);
      const total = data?.deployments?.cost && data?.deployments?.cost < .01 ? `< $0.01`: `$${data?.deployments?.cost.toFixed(2)}`;
      setTotalDeployments(total);
    }
  }, [data, data?.keys, data?.deployments]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };

  return (<>
    <div className=" ">
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButton value="deployments" style={{textTransform :"none"}}>By deployments</ToggleButton>
        <ToggleButton value="keys" style={{textTransform :"none"}}>By keys</ToggleButton>
      </ToggleButtonGroup>

      <Grid container spacing={1}>
        {/* breakdown tables */}
        <Grid size={{ xs: 12, md: 6 }}>
          <Stack direction="row" spacing={1} sx={{ mt: 3 }}>
            <Card variant="outlined" style={{width: '100%'}}>
              <Box sx={{ p: 2 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="subtitle1" component="div">
                    Total Cost
                  </Typography>
                  <Typography variant="subtitle1" component="div">
                    <span className="font-bold flex items-center text-med text-blue-400">{alignment && alignment === 'keys' ? totalKeys : totalDeployments}</span>
                  </Typography>
                </Stack>
              </Box>
              <Box sx={{ p: 2, pt: 0 }}>
                {alignment && alignment === 'keys' ?
                  <DataGrid 
                    rows={billingDataKeysItems} 
                    columns={columnsKeys} 
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                      },
                    }}
                    autoHeight={true}
                    pageSizeOptions={[25, 50, 100]}
                    slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                    sx={{ '--DataGrid-overlayHeight': '300px', '& .MuiDataGrid-scrollbar--horizontal': {overflowX: 'hidden'}, minHeight: "500px" }}
                    // checkboxSelection
                  />
                : 
                  <DataGrid 
                    rows={billingDataDeploymentsItems} 
                    columns={columnsDeployments} 
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                      },
                    }}
                    autoHeight={true}
                    pageSizeOptions={[25, 50, 100]}
                    slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                    sx={{ '--DataGrid-overlayHeight': '300px', '& .MuiDataGrid-scrollbar--horizontal': {overflowX: 'hidden'}, minHeight: "500px"}}
                    // checkboxSelection
                  />
                }
              </Box>
            </Card>
          </Stack>
        </Grid>
        {/* breakdown charts */}
        <Grid size={{ xs: 12, md: 6 }}>
          <Stack direction="row" spacing={1} sx={{ mt: 3 }}>
            <Card variant="outlined" style={{width: '100%'}}>
              <Box sx={{ p: 2 }}>
                <BillingOverviewBreakdownChart dateRange={dateRange} type={alignment} />
              </Box>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </div>
  </>);
}

export default BillingOverviewBreakdownServerless;