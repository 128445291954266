import {
    Outlet,
  } from "react-router-dom";
import Navbar from "./Navbar";
import Aside from "./Aside";
import React from "react";
import Drawer from '@mui/material/Drawer';
// Parasail imports
import Footer from "components/Footer";
// import Feedback from "components/views/common/Feedback";
import 'styles/common.css';

const Layout = () => {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
  <>
    <div>
      <div className="app-background " style={{minHeight: "100vh"}}>
          {/* top nav */}
          <Navbar toggleDrawer={toggleDrawer(true)} />

          {/* left nav */}
          <Drawer open={open} onClose={toggleDrawer(false)}>
            <Aside />
          </Drawer>
          
          {/* main body */}
          <div
            id="content"
            className="p-4"
          >
            <Outlet />
          </div>
      </div>
      <Footer />
    </div>

{/* might need this back for none footer page */}
    {/* <div>
      <Feedback />
    </div> */}
  </>
  );
};

export default Layout;
